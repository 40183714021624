// extracted by mini-css-extract-plugin
export var lightMode = "index-module--light-mode--2z1e7";
export var container = "index-module--container--2exL2";
export var active = "index-module--active--14oKv";
export var appear = "index-module--appear--1ip9r";
export var controls = "index-module--controls--1jsF0";
export var control = "index-module--control--NZ5OF";
export var activeBtn = "index-module--active-btn--1g_WU";
export var themeBtn = "index-module--theme-btn--PNzIr";
export var headerContent = "index-module--header-content--1Dcfk";
export var leftHeader = "index-module--left-header--3lKtH";
export var hShape = "index-module--h-shape--2fFeu";
export var image = "index-module--image--3vzxY";
export var rightHeader = "index-module--right-header--3PKx1";
export var name = "index-module--name--2aY9v";
export var aboutContainer = "index-module--about-container--2gBJw";
export var rightAbout = "index-module--right-about--1v45G";
export var aboutItem = "index-module--about-item--2JK6X";
export var abtText = "index-module--abt-text--3oUMf";
export var largeText = "index-module--large-text--HMoL8";
export var smallText = "index-module--small-text--3in6K";
export var leftAbout = "index-module--left-about--1b2SQ";
export var aboutStats = "index-module--about-stats--2XzqM";
export var progressBars = "index-module--progress-bars--30H5d";
export var progressBar = "index-module--progress-bar--JBkaC";
export var progTitle = "index-module--prog-title--1VHJZ";
export var progressCon = "index-module--progress-con--OJes-";
export var progText = "index-module--prog-text--6NXOU";
export var progress = "index-module--progress--19aef";
export var html = "index-module--html--1TSIe";
export var css = "index-module--css--3X4PY";
export var js = "index-module--js---TojG";
export var react = "index-module--react--2aJPR";
export var node = "index-module--node--3scxo";
export var python = "index-module--python--3JEyj";
export var statTitle = "index-module--stat-title--dQEaA";
export var timeline = "index-module--timeline--24aS-";
export var timelineItem = "index-module--timeline-item--3A6aY";
export var tlIcon = "index-module--tl-icon--33PpC";
export var tlDuration = "index-module--tl-duration--SNOZ3";
export var portText = "index-module--port-text--3MZ36";
export var portfolios = "index-module--portfolios--_xq-c";
export var portfolioItem = "index-module--portfolio-item--24dKk";
export var hoverItems = "index-module--hover-items--fdTz4";
export var icons = "index-module--icons--2hqEM";
export var icon = "index-module--icon--36Pl4";
export var blogs = "index-module--blogs--1ZTQs";
export var blog = "index-module--blog--xDBM6";
export var blogText = "index-module--blog-text--1FUz2";
export var contactContentCon = "index-module--contact-content-con--1jwsw";
export var leftContact = "index-module--left-contact--UViVN";
export var contactInfo = "index-module--contact-info--5Hh5w";
export var contactItem = "index-module--contact-item--1KmeY";
export var contactIcon = "index-module--contact-icon--389Ht";
export var rightContact = "index-module--right-contact--1Iz2c";
export var inputControl = "index-module--input-control--1CYfF";
export var iC2 = "index-module--i-c-2---I2Ua";
export var submitBtn = "index-module--submit-btn--2M4zV";
export var btnCon = "index-module--btn-con--LBKyf";
export var mainBtn = "index-module--main-btn--KO4Et";
export var btnText = "index-module--btn-text--3RsYK";
export var btnIcon = "index-module--btn-icon--4AxrX";
export var mainTitle = "index-module--main-title--2C344";
export var bgText = "index-module--bg-text--j9W57";